












































































































































import Vue from 'vue'
import blockCardDescBg from '@/assets/images/blockChainBrowser/blockDetail/block-card-desc-bg.png'
import blockIcon from '@/assets/images/blockChainBrowser/blockDetail/block.png'
import mCopyBtn from './m-copyBtn.vue'
import lastBlockBg from '@/assets/images/blockChainBrowser/blockDetail/lastBlockBg.png'
import nextBlockBg from '@/assets/images/blockChainBrowser/blockDetail/nextBlockBg.png'

export default Vue.extend({
  components: { mCopyBtn },
  props: {
    loading: Boolean,
    hash: String,
    merkelgen: String,
    stateHash: String,
    blockTime: Number,
    txCount: Number,
    height: [Number, String],
    lastBlockHeight: Number,
    nextBlockHeight: Number,
  },
  data() {
    return {
      blockCardDescBg,
      blockIcon,
      lastBlockBg,
      nextBlockBg,
    }
  },
})
