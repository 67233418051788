
































































































































import Vue from "vue";
export default Vue.extend({
  props: ["row"],
  computed: {
    foundation() {
      return process.env.VUE_APP_FOUNDATION;
    },
  },
});
