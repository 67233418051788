












































































import VueTypedMixins from "vue-typed-mixins";
import blockDetailMixin from "@/mixin/blockDetail";
import MChainSearch from "@/components/mobile/m-chainSearch.vue";
import MBlockDescCard from "@/components/mobile/m-block-desc-card.vue";
import MTabs from "@/components/mobile/m-tabs.vue";
import MTxItem from "@/components/mobile/m-txItem.vue";
import MPageContainer from "@/components/mobile/m-pageContainer.vue";
import MConsensusNode from "@/components/mobile/m-consensus-node.vue";
import Select from "@/components/pc/Select.vue";
export default VueTypedMixins(blockDetailMixin).extend({
  components: {
    MChainSearch,
    MBlockDescCard,
    MTabs,
    MTxItem,
    MPageContainer,
    MConsensusNode,
    Select,
  },
});
