























import Vue, { PropType } from 'vue'
export default Vue.extend({
  props: {
    focusedTab: String,
    setFocusedTab: Function,
    tabList: Array as PropType<
      {
        name: string
        value: string
      }[]
    >,
  },
})
